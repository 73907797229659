import { useState } from "react";
import { faChevronDown, faChevronUp } from "@fortawesome/pro-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import classNames from "classnames";
import classnames from "classnames";

const faqs = [
  {
    question: "What is Vital?",
    answer:
      "Vital is a health optimization platform that uses advanced biometrics and AI-driven insights to help you achieve peak vitality. By tracking metrics from wearables, blood tests, and lifestyle factors, Vital provides personalized recommendations and tools for safe, effective experimentation with your health.",
  },
  {
    question: "How is Vital different from other health apps?",
    answer:
      "Unlike generic health trackers, Vital aggregates a comprehensive range of biomarkers and combines them into a single, actionable Total Vitality Score. This approach allows you to understand your health in a more holistic way and makes optimizing vitality a tangible goal.",
  },
  {
    question: "Who is Vital designed for?",
    answer:
      "Vital is built for anyone serious about optimizing their health, whether you’re a biohacker, athlete, or someone striving for better energy, focus, and longevity. If you want an objective and accurate comprehensive analysis of your health, Vital is for you.",
  },
  {
    question: "Do I need a medical background to use Vital?",
    answer:
      "Not at all. Vital is designed to be intuitive and user-friendly. We provide a comprehensive guide to help you understand your results and make informed decisions about your health regardless of your education background.",
  },
  {
    question: "How does the Total Vitality Score work?",
    answer:
      "Vital helps you track your health with a single, comprehensive health score: the Total Vitality Score. Scores range from 0 to 100, with higher scores indicating better overall health. Read more about how it's calculated here.",
  },
  {
    question: "Is my data private and secure?",
    answer:
      "Absolutely. Your data is encrypted and belongs to you. Vital uses state-of-the-art security protocols to ensure that your information is safe, and we never sell your data to third parties.",
  },
  {
    question: "Can Vital help me prevent disease?",
    answer:
      "Yes. By tracking your biomarkers and providing personalized recommendations, Vital helps you identify potential health risks early and take proactive steps to prevent them. Think of it as shifting from reactive healthcare to proactive health optimization.",
  },
  {
    question: "What kind of devices or data does Vital integrate with?",
    answer:
      "Vital is an iOS app compatible with Apple Health which integrates with popular wearable devices and health data platforms. You can also add data to Vital manually and write to Apple Health.",
  },
];

const FAQ = () => {
  const [openIndex, setOpenIndex] = useState<number | null>(null);

  return (
    <section
      className={classnames(
        "flex w-screen max-w-screen-xl flex-col items-start gap-8 px-6 sm:px-12",
      )}
    >
      <h2 className="w-full text-3xl font-medium">
        Frequently Asked Questions
      </h2>
      <div className="flex w-full flex-col gap-4">
        {faqs.map((faq, index) => (
          <div
            key={index}
            className="w-full overflow-hidden rounded-lg border border-base-300 bg-base-100"
          >
            <button
              className="flex w-full items-center justify-between p-4"
              onClick={() => setOpenIndex(openIndex === index ? null : index)}
            >
              <span className="text-left font-medium">{faq.question}</span>
              <FontAwesomeIcon
                icon={openIndex === index ? faChevronUp : faChevronDown}
                className={classNames(
                  "h-4 w-4 shrink-0 transform transition-transform duration-200",
                )}
              />
            </button>
            <div
              className={classNames(
                "grid transition-all duration-200",
                openIndex === index ? "grid-rows-[1fr]" : "grid-rows-[0fr]",
              )}
            >
              <div className="overflow-hidden">
                <div className="px-4 pb-4">
                  <p className="font-normal text-base-content/60">
                    {faq.answer}
                  </p>
                </div>
              </div>
            </div>
          </div>
        ))}
      </div>
    </section>
  );
};

export default FAQ;
