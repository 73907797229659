import Image from "next/image";
import classnames from "classnames";
import { motion } from "framer-motion";

const EvaluationSources = () => {
  return (
    <section className="flex w-screen max-w-screen-xl flex-col items-center gap-0 px-6 sm:gap-12 sm:px-12">
      <motion.h2
        initial={{ opacity: 0 }}
        whileInView={{ opacity: 1 }}
        transition={{ duration: 0.3, delay: 0.2 }}
        viewport={{ once: true }}
        className={classnames(
          "mb-2 w-full text-center font-semibold text-base-content/30",
          "text-2xl sm:text-3xl md:text-4xl",
        )}
      >
        <span className=" bg-gradient-to-r from-base-content/60 to-base-content bg-clip-text font-semibold text-transparent">
          Evaluations supported by data
        </span>{" "}
        from{" "}
        <span className="whitespace-nowrap font-semibold text-base-content/30">
          reputable sources
        </span>
      </motion.h2>
      <div
        className={classnames(
          "flex flex-row items-center justify-center gap-12 md:gap-24",
          "scale-75 md:scale-100",
          "flex-wrap p-0  md:flex-nowrap md:p-8",
        )}
      >
        {sources.map((source) => (
          <Source key={source.name} {...source} />
        ))}
      </div>
    </section>
  );
};

const sources = [
  {
    name: "Chochran Review logo",
    srcLight: "/images/home/data-sources/cochrane-light.png",
    srcDark: "/images/home/data-sources/cochrane-dark.png",
    w: 128,
    h: 128,
    delay: 0.6,
  },
  {
    name: "National Strength and Conditioning Association logo",
    srcLight: "/images/home/data-sources/nsca-light.png",
    srcDark: "/images/home/data-sources/nsca-dark.png",
    w: 212,
    h: 60,
    delay: 0.2,
  },
  {
    name: "Mayo Clinic logo",
    srcLight: "/images/home/data-sources/mayo-clinic-light.png",
    srcDark: "/images/home/data-sources/mayo-clinic-dark.png",
    w: 105,
    h: 128,
    delay: 0.4,
  },
  {
    name: "Harvard Chan School of Public Health logo",
    srcLight: "/images/home/data-sources/harvard-chan-light.png",
    srcDark: "/images/home/data-sources/harvard-chan-dark.png",
    w: 211,
    h: 77,
    delay: 0.6,
  },
  {
    name: "American College of Sports Medicine logo",
    srcLight: "/images/home/data-sources/acsm-light.png",
    srcDark: "/images/home/data-sources/acsm-dark.png",
    w: 128,
    h: 128,
    delay: 0.4,
  },
];

interface Source {
  name: string;
  srcLight: string;
  srcDark: string;
  w: number;
  h: number;
  delay: number;
}

const Source = ({ name, srcLight, srcDark, w, h, delay }: Source) => {
  return (
    <motion.div
      initial={{ opacity: 0, y: 20 }}
      whileInView={{ opacity: 0.5, y: 0 }}
      transition={{ duration: 0.5, delay }}
      whileHover={{ opacity: 1, transition: { duration: 0 } }}
      viewport={{ once: true }}
    >
      <Image
        src={srcLight}
        alt={name}
        width={w}
        height={h}
        className="dark:hidden"
        draggable={false}
      />
      <Image
        src={srcDark}
        alt={name}
        width={w}
        height={h}
        className="hidden dark:block"
        draggable={false}
      />
    </motion.div>
  );
};

export default EvaluationSources;
